import "./styles/App.css";

import Header from './utils/Header';
import Menu from './utils/Menu';
import Hero from './utils/Hero';
import Footer from './utils/Footer';
import Advantages from './components/Advantages';
import Solutions from './components/Solutions';
import Bens from './components/Bens';
import Partner from './components/Partner';
import CurrentTheme from './components/CurrentTheme';
import Offer from "./components/Offer";
import Advisor from './components/Advisor';
import config from "./config";
import { ContextProvider } from './context';


function App() {
  return (
    <ContextProvider>
      <div
        id="app"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
            " 0%, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
            " 16%)"
        }}>
        <Header />
        <Menu />
        {
          config.offer ? <Offer /> : null
        }
        <Hero />
        <Bens />
        <Advisor />
        <Solutions />
        <Partner />
        <Advantages />
        <Footer />
      </div>
    </ContextProvider>
  );
}

export default App;