import React from "react";
import "./../../styles/components/Partner.css";

export default function PartnerDE() {
  return (
    <div id="bens-section-11">
      <div className="bens-container">
        <h2 className="section-11-title">
          Der ideale Partner für Unternehmen für den CHF-EUR-Wechsel
        </h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-security.webp"
              alt="Sécurité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Sicherheit</h3>
            <p className="box-desc">
              Unser Unternehmen ist Mitglied der ARIF, einer
              Selbstregulierungsorganisation von der FINMA genehmigt. Führen Sie
              Ihre Arbeiten in absoluter Sicherheit durch. Unser Devisendienst
              wird überwacht, um die zu erfüllen Richtlinien der Schweizerischen
              Eidgenossenschaft
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-premium.webp"
              alt="Service Premium Pro"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Dienst Premium Pro</h3>
            <p className="box-desc">
              Berater kommen auf Ihren Wunsch zu Ihnen ins Haus begleiten Sie
              von Ihrer Anmeldung bis zur Realisierung Ihres Devisengeschäfte
              chf eur
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-simple.webp"
              alt="Simplicité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Einfachheit</h3>
            <p className="box-desc">
              Ben S. Digital Change macht es einfach, Ihre Gelder zu verwalten
              internationaler Handel
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-rocket.webp"
              alt="Rapidité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Geschwindigkeit</h3>
            <p className="box-desc">
              Sie erhalten Ihr Geld in der Regel in EUR oder CHF umgerechnet 24
              Stunden (Werktag)
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-economie.webp"
              alt="Économie"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Wirtschaft</h3>
            <p className="box-desc">
              Dank günstigerem CHF-EUR-Wechselkurs und ohne Gebühren versteckt,
              sparen Sie bei jedem Umtauschvorgang
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
