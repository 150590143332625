import React from "react";
import "./../../styles/components/Partner.css";

export default function PartnerEN() {
  return (
    <div id="bens-section-11">
      <div className="bens-container">
        <h2 className="section-11-title">
          The ideal partner for companies for CHF-EUR exchange
        </h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-security.webp"
              alt="Sécurité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Security</h3>
            <p className="box-desc">
              Our company is a member of ARIF, a self-regulatory organization
              approved by FINMA. Carry out your operations in complete safety.
              Our foreign exchange service is monitored to meet the directives
              of the Swiss Confederation
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-premium.webp"
              alt="Service Premium Pro"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Service Premium Pro</h3>
            <p className="box-desc">
              Advisors come to your premises at your request to accompany you
              from your registration to the realization of your exchange
              transactions chf eur
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-simple.webp"
              alt="Simplicité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Simplicity</h3>
            <p className="box-desc">
              Ben S. Digital Change makes it easy to manage your funds
              international trade
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-rocket.webp"
              alt="Rapidité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Speed</h3>
            <p className="box-desc">
              You will receive your funds converted into EUR or CHF generally in
              24 hours (working day)
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-economie.webp"
              alt="Économie"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Economy</h3>
            <p className="box-desc">
              Thanks to a more advantageous CHF-EUR exchange rate, and no fees
              hidden, you save on each exchange transaction
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
