import React, { useContext } from "react";
import { Context } from "./../../context";

import PartnerFR from "./fr";
import PartnerDE from "./de";
import PartnerIT from "./it";
import PartnerEN from "./en";

export default function Partner() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <PartnerDE />;
    case "it":
      return <PartnerIT />;
    case "en":
      return <PartnerEN />;
    default:
      return <PartnerFR />;
  }
}
