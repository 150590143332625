import React from "react";
import "./../../styles/components/Advantages.css";
import CurrentTheme from "./../CurrentTheme";

export default function AdvantagesDE() {
  return (
    <div
      className="advantages"
      style={{
        background:
          "linear-gradient(0deg, " +
          CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
          " 0%, " +
          CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
          " 50%)",
      }}
    >
      <div className="bens-container">
        <h2 className="box-title">
          Die Vorteile des Online-Wechsels von Währungen
        </h2>
        <div className="box-container">
          <div className="box-item">
            <img
              src="/img/adv-01.webp"
              alt="Adv 01"
              loading="lazy"
              width={176}
              height={158}
            />
            <h3>Flexibilität</h3>
            <p>
              Kein bestimmter Ort! Keine Stunden! Um die Währung zu ändern
              online, es ist möglich, es zu tun, egal wo wir liegen. Alles, was
              Sie brauchen, ist eine Internetverbindung, a Smartphone, fertig.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-02.webp"
              alt="Adv 02"
              loading="lazy"
              width={173}
              height={184}
            />
            <h3>Automatisieren Sie Ihre Transaktionen</h3>
            <p>
              Regelmäßige Transaktionen? Müssen Sie Ihr Gehalt umrechnen?
              Sorglos. Geben Sie Ihren Konvertierungsauftrag auf und
              automatisieren Sie ihn. Jeden Monat kümmern wir uns um den Eingang
              Ihres Geldes den Austausch Ihres Gehalts und wir überweisen es
              Ihnen sofort auf das Zielkonto.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-03.webp"
              alt="Adv 03"
              loading="lazy"
              width={158}
              height={177}
            />
            <h3>Mehr Sicherheit</h3>
            <p>
              Beim digitalen Austausch besteht kein Risiko mehr (Diebstahl,
              Verlust). Versicherungsschutz bei einer großen
              Versicherungsgesellschaft Die Schweiz ist auch beauftragt,
              jegliches Risiko zu vermeiden möglich.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-04.webp"
              alt="Adv 04"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3>Ersparnisse</h3>
            <p>Sparen ist für Sie essenziell?</p>
            <p>
              Profitieren Sie beim Online-Umtausch von einem etwas günstigeren
              Kurs im Vergleich zu physischen Wechselstuben.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
