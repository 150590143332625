const themes = {
  default: "default",
  noel: "noel",
  halloween: "halloween",
  easter: "easter",
  summer: "summer",
};

const config = {
  theme: themes.default,
  offer: true,
  promotion: false,
  apiUrl: "https://bens-digital-change.com/",
  appUrl: "https://entreprise.bens-digital-change.com",
  version: "v1.0.12",
};

export default config;
