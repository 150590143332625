import React from "react";
import "./../../styles/components/Partner.css";

export default function PartnerFR() {
  return (
    <div id="bens-section-11">
      <div className="bens-container">
        <h2 className="section-11-title">
          Le partenaire idéal des entreprises pour le change CHF-EUR
        </h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-security.webp"
              alt="Sécurité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Sécurité</h3>
            <p className="box-desc">
              Notre société est membre de l’ARIF, organisme d’autorégulation
              agréé par la FINMA. Procédez à vos opérations en toute sécurité.
              Notre service de change est contrôlé afin de répondre aux
              directives de la confédération helvétique
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-premium.webp"
              alt="Service Premium Pro"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Service Premium Pro</h3>
            <p className="box-desc">
              Des conseillers se déplacent dans vos locaux à votre demande pour
              vous accompagner de votre inscription à la réalisation de vos
              opérations de change chf eur
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-simple.webp"
              alt="Simplicité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Simplicité</h3>
            <p className="box-desc">
              Ben S. Digital Change facilite la gestion des fonds de votre
              commerce à l’international
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-rocket.webp"
              alt="Rapidité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Rapidité</h3>
            <p className="box-desc">
              Vous recevrez vos fonds convertis en EUR ou en CHF généralement en
              24 h (jour ouvré)
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-economie.webp"
              alt="Économie"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Économie</h3>
            <p className="box-desc">
              Grâce à un taux de change CHF-EUR plus avantageux, et aucuns frais
              cachés, vous économisez sur chaque opération de change
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
