import React from "react";
import "./../../styles/components/Solutions.css";
import CurrentTheme from "./../CurrentTheme";

export default function SolutionsFR() {
  return (
    <div className="solutions">
      <div className="bens-container">
        <div className="solutions-top">
          <div className="box-col">
            <div className="media-group has-one">
              <a
                className="media-item"
                href="https://bens-digital-change.com/achat-et-vente-a-linternational"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Achat / Vente à l’International</h4>
                <img
                  src="/img/sol-04.webp"
                  alt="Sol 04"
                  loading="lazy"
                  width={351}
                  height={363}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/payez-vos-fournisseurs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Paiement fournisseur</h4>
                <img
                  src="/img/sol-03.webp"
                  alt="Sol 03"
                  loading="lazy"
                  width={358}
                  height={351}
                />
              </a>
            </div>
          </div>
          <div className="box-col solutions-top-right">
            <h2>La solution de change adaptée à vos besoins professionnels</h2>
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              Inscription Gratuite et sans engagement
            </a>
            <a
              href="https://calendly.com/bens_digital/demande-d-assistance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contacter nos conseillers
            </a>
          </div>
        </div>

        <div className="section-08-box solutions-bottom">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/get-mobile-back.webp"
              alt="Get Mobile"
              className="box-img"
              loading="lazy"
              width={733}
              height={671}
            />
          </div>
          <div className="box-col">
            <h2 className="box-title">
              Notre solution est disponible sur mobile
            </h2>
            <img
              src="/img/bens-title-dark.svg"
              alt="Bens"
              className="box-desc-img"
              loading="lazy"
              width={399}
              height={51}
            />
            <p className="box-long-desc">
              Réalisez l’ensemble de vos opérations de change sur notre
              application disponible sur Android comme sur IOS. Vous souhaitez
              changer votre devise à un moment précis ?
            </p>
            <p className="box-long-desc">
              Notre application vous offre la possibilité de créer une alerte
              afin de vous prévenir quand le taux de change atteint celui que
              vous désirez.
            </p>
            <a
              href="https://bens-digital-change.ch/download-mobile-app"
              className="box-store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bens-digital-change.com/images/v2/bens-stores.webp"
                alt="Bens Store"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="solutions-back"
        style={{
          background:
            "linear-gradient(180deg, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
            " 0%, " +
            CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
            " 100%)",
        }}
      ></div>
    </div>
  );
}
