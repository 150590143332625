import React from "react";
import "./../../styles/components/Advantages.css";
import CurrentTheme from "./../CurrentTheme";

export default function AdvantagesIT() {
  return (
    <div
      className="advantages"
      style={{
        background:
          "linear-gradient(0deg, " +
          CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
          " 0%, " +
          CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
          " 50%)",
      }}
    >
      <div className="bens-container">
        <h2 className="box-title">I vantaggi di cambiare valuta online</h2>
        <div className="box-container">
          <div className="box-item">
            <img
              src="/img/adv-01.webp"
              alt="Adv 01"
              loading="lazy"
              width={176}
              height={158}
            />
            <h3>Flessibilità</h3>
            <p>
              Nessuna posizione specifica! Nessun orario! Per cambiare valuta
              online, è possibile farlo ovunque siamo situati. Tutto ciò di cui
              hai bisogno è una connessione Internet, a smartphone e il gioco è
              fatto.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-02.webp"
              alt="Adv 02"
              loading="lazy"
              width={173}
              height={184}
            />
            <h3>Automatizza le tue transazioni</h3>
            <p>
              Transazioni regolari? Hai bisogno di convertire il tuo stipendio?
              Spensierato. Inserisci il tuo ordine di conversione e
              automatizzalo. Ogni mese, al ricevimento dei tuoi soldi, ci
              occupiamo noi lo scambio del tuo stipendio e noi te lo trasferiamo
              immediatamente sul conto di destinazione.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-03.webp"
              alt="Adv 03"
              loading="lazy"
              width={158}
              height={177}
            />
            <h3>Più sicurezza</h3>
            <p>
              Con lo scambio digitale non c'è più alcun rischio (furto,
              smarrimento). Copertura assicurativa con primaria compagnia
              assicurativa Anche la Svizzera è impegnata a prevenire qualsiasi
              rischio possibile.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-04.webp"
              alt="Adv 04"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3>Risparmio</h3>
            <p>Risparmiare è fondamentale per te?</p>
            <p>
              Con il cambio online, usufruisci di un tasso leggermente più
              vantaggioso rispetto agli uffici di cambio fisici.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
