import React, { useState } from "react";
import "./../../styles/utils/Hero.css";
import Calculator from "./../../components/Calculator";
import TrustpilotWidget from "./../../components/TrustpilotWidget";
import CurrentTheme from "./../../components/CurrentTheme";

import YouTube from "react-youtube";

export default function HeroDE() {
  const [visible, setVisible] = useState(false);

  const handleBtnOpen = () => {
    setVisible(true);
  };

  const handleBtnClose = () => {
    setVisible(false);
  };

  const handleWrapper = () => {
    setVisible(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  const destroyVideo = (event) => {
    event.target.destroyVideo();
  };

  return (
    <div id="bens-hero">
      <div className="bens-container">
        {process.env.REACT_APP_HAS_PROMOTION === "true" ? (
          <div className="hero-col is-top-col">
            <div className="hero-col-box">
              <h1>Willkommensangebot:</h1>
              <h2 className="is-bigger">20 CHF Kostenlos</h2>
              <h2>für Ihre erste Transaktion*</h2>
            </div>
            <div className="hero-col-box is-small has-promotion">
              <div className="is-promotion">
                <h3>Aktion gültig bis 31.12.2023</h3>
                <p>
                  Angebot gültig für jeden Neukunden für jede Transaktion bis
                  31.12.2023 ab einem Betrag von CHF 3'000 oder mehr
                </p>
                <p className="is-bigger">mit Gutscheincode</p>
                <span>PRO23</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-col">
            <div className="hero-col-box has-not-promotion">
              <h1>
                Wechseln Sie Ihre Schweizer Franken dank unserer
                Online-Plattform ganz einfach zu einem sehr attraktiven Kurs in
                Euro
              </h1>
            </div>
          </div>
        )}

        <div className="hero-col">
          {process.env.REACT_APP_THEME === "default" ? (
            <div className="hero-col-box">
              <div className="hero-box-container">
                <div className="hero-row">
                  <h2 className="hero-title">
                    Eine Devisenlösung, die Ihren persönlichen Zielen entspricht
                  </h2>
                  <div className="hero-video">
                    <h3 className="video-title">
                      Wie funktioniert unser Service:
                    </h3>
                    <div id="video-action" onClick={handleBtnOpen}>
                      <img
                        src="https://bens-digital-change.com/images/v2/hero-video-capt.webp"
                        alt="Ben S. Digital Change"
                        className="caption-img"
                        width={331}
                        height={185}
                        loading="lazy"
                      />
                    </div>
                    {visible ? (
                      <div
                        id="video-wrapper"
                        onClick={handleWrapper}
                        className={visible ? "is-show" : ""}
                      >
                        <YouTube
                          id="iframe"
                          videoId="p6_RdMZBgO8"
                          opts={{
                            playsinline: 0,
                            autoplay: 1,
                            origin: window.location.origin,
                          }}
                          onReady={onPlayerReady}
                          onEnd={destroyVideo}
                        />
                        <div id="video-close-btn" onClick={handleBtnClose}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="hero-partner">
                    <h5 className="partner-title">
                      Die Multichannel-Lösung mit
                    </h5>
                    <img
                      className="partner-img"
                      src="https://bens-digital-change.com/images/logo/bens_shop_change.svg"
                      alt="Ben S. Shop Change"
                      width={100}
                      height={67}
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="hero-row">
                  <img
                    className="hero-bens-img"
                    src="https://bens-digital-change.com/images/v2/char_bens.webp"
                    alt="The Ben S."
                    width={245}
                    height={556}
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ERSTELLEN SIE IHREN KUNDENBEREICH
                </a>
              </div>
            </div>
          ) : (
            <div
              className="hero-col-box has-cover"
              style={{
                backgroundImage:
                  "url(" +
                  CurrentTheme(process.env.REACT_APP_THEME)["cover"] +
                  ")",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                className="has-cover-filter"
                style={{
                  background:
                    "linear-gradient(135deg, " +
                    CurrentTheme(process.env.REACT_APP_THEME)["color"][0] +
                    " 0%, " +
                    CurrentTheme(process.env.REACT_APP_THEME)["color"][1] +
                    " 100%)",
                }}
              ></div>

              <div className="hero-box-container">
                <div className="hero-row">
                  <img
                    src={CurrentTheme(process.env.REACT_APP_THEME)["cover"]}
                    alt="Cover"
                    className="hero-media"
                    loading="lazy"
                    width={1170}
                    height={629}
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ERSTELLEN SIE IHREN KUNDENBEREICH
                </a>
              </div>
            </div>
          )}

          <div className="hero-col-box is-small has-calculator">
            <Calculator></Calculator>
            <TrustpilotWidget></TrustpilotWidget>
            <a
              href="https://bens-digital-change.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-action-btn is-red"
            >
              {"Erfahren Sie mehr"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
