import React from "react";
import "./../../styles/components/Advisor.css";

export default function AdvisorDE() {
  return (
    <div id="bens-section-13">
      <div className="bens-container">
        <h2 className="bens-section-13-title">
          Unsere Berater kommen auf Wunsch zu Ihnen ins Haus
        </h2>
        <ul className="bens-section-13-desc">
          <li>Um Ihren Registrierungsprozess zu vereinfachen</li>
          <li>Um Ihnen ein personalisiertes Follow-up zu bieten</li>
        </ul>
        <h3 className="bens-section-13-subtitle">
          Ein Premium-Service verfügbar
        </h3>
        <div className="bens-section-13-list">
          <div className="bens-section-13-item">
            <img
              src="https://bens-digital-change.com/images/v2/icon-custom-phone.svg"
              alt="Phone"
              loading="lazy"
            />

            <a
              className="item-phone"
              href="tel:+41794336387"
              target="_blank"
              rel="noopener noreferrer"
            >
              +41 79 433 63 87
            </a>
          </div>
          <div className="bens-section-13-item">
            <img
              src="https://bens-digital-change.com/images/v2/icon-custom-envelope.svg"
              alt="Mail"
              loading="lazy"
            />
            <a
              className="item-mail"
              href="mailto:contact@bens-digital-change.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@bens-digital-change.com
            </a>
          </div>
        </div>
      </div>
      <div className="section-13-row">
        <a
          href="tel:+41794336387"
          target="_blank"
          rel="noopener noreferrer"
          className="section-13-btn"
        >
          Rufen Sie den Premium-Service an
        </a>
        <a
          href="https://calendly.com/bens_digital/demande-d-assistance"
          target="_blank"
          rel="noopener noreferrer"
          className="section-13-btn"
        >
          Vereinbaren Sie einen Termin mit einem Berater
        </a>
      </div>
    </div>
  );
}
