import React from "react";
import "./../../styles/components/Partner.css";

export default function PartnerIT() {
  return (
    <div id="bens-section-11">
      <div className="bens-container">
        <h2 className="section-11-title">
          Il partner ideale per le aziende per lo scambio CHF-EUR
        </h2>
        <div className="section-11-box">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-security.webp"
              alt="Sécurité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Sicurezza</h3>
            <p className="box-desc">
              La nostra azienda è membro dell'ARIF, un'organizzazione di
              autoregolamentazione approvato dalla FINMA. Svolgi le tue
              operazioni in totale sicurezza. Il nostro servizio di cambio è
              monitorato per soddisfare le direttive della Confederazione
              Svizzera
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-premium.webp"
              alt="Service Premium Pro"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Servizio Premium Pro</h3>
            <p className="box-desc">
              I consulenti vengono presso la tua sede su tua richiesta
              accompagnarti dalla tua iscrizione alla realizzazione del tuo
              operazioni di cambio chf eur
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-simple.webp"
              alt="Simplicité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Semplicità</h3>
            <p className="box-desc">
              Ben S. Digital Change semplifica la gestione dei tuoi fondi
              commercio internazionale
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-rocket.webp"
              alt="Rapidité"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Velocità</h3>
            <p className="box-desc">
              Riceverai i tuoi fondi convertiti in EUR o CHF generalmente in 24
              ore (giorno lavorativo)
            </p>
          </div>
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/icon-home-economie.webp"
              alt="Économie"
              className="box-img"
              loading="lazy"
              height={120}
            />
            <h3 className="box-title">Economia</h3>
            <p className="box-desc">
              Grazie a un tasso di cambio CHF-EUR più vantaggioso e senza
              commissioni nascosto, risparmi su ogni transazione di scambio
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
