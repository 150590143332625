import React, { useContext } from "react";
import { Context } from "./../../context";

import AdvisorFR from "./fr";
import AdvisorDE from "./de";
import AdvisorIT from "./it";
import AdvisorEN from "./en";

export default function Advisor() {
  const { state } = useContext(Context);

  switch (state.locale) {
    case "de":
      return <AdvisorDE />;
    case "it":
      return <AdvisorIT />;
    case "en":
      return <AdvisorEN />;
    default:
      return <AdvisorFR />;
  }
}
